import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LiveEventsRoutingModule } from "./live-events-routing.module";
import { LiveEventsListComponent } from "./pages/events/live-events-list/live-events-list.component";
import { LiveEventFormComponent } from "./pages/live-event-form/live-event-form.component";
import { FormsModule } from "@angular/forms";
import { SharedModule } from "../shared/shared.module";
import { LiveEventDetailsComponent } from "./pages/event-details/live-event-details.component";
import { FontAwesome } from "../font-awesome/font-awesome.module";
import { SourceDetailsWrapperComponent } from "./pages/event-details/source-details-wrapper/source-details-wrapper.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { MatTooltipModule } from "@angular/material/tooltip";
import { LiveEventStageTableComponent } from "./components/live-event-stage-table/live-event-stage-table.component";
import { LiveEventClipTableComponent } from "./components/live-event-clip-table/live-event-clip-table.component";

@NgModule({
    declarations: [
        LiveEventFormComponent,
        LiveEventsListComponent,
        LiveEventDetailsComponent,
        SourceDetailsWrapperComponent,
        LiveEventStageTableComponent,
        LiveEventClipTableComponent
    ],
    imports: [
        CommonModule,
        LiveEventsRoutingModule,
        NgbModule,
        FormsModule,
        SharedModule,
        FontAwesome,
        TourMatMenuModule,
        MatTooltipModule
    ]
})
export class LiveEventsModule {}

import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Data, Router } from "@angular/router";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

import { take } from "rxjs/operators";

import { SSO } from "./sso";

import { LoginService } from "./login.service";
import { TitleService } from "../../services/title.service";
import { AccountSettings } from "src/app/services/settings.service";
import { UrlBuilderService } from "src/app/services/url-builder.service";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;
    error = "";
    showZenLogin = false;
    ssos: SSO[];
    accountSettings: AccountSettings;
    private urlBuilderService = inject(UrlBuilderService);
    private formBuilder = inject(UntypedFormBuilder);
    private router = inject(Router);
    private loginService = inject(LoginService);
    private titleService = inject(TitleService);
    private activatedRoute = inject(ActivatedRoute);

    ngOnInit() {
        this.titleService.setTitle("ZEN_MASTER", "");

        this.loginForm = this.formBuilder.group({
            email: ["", Validators.required],
            password: ["", Validators.required]
        });

        this.loginService.ssos.pipe(take(1)).subscribe((ssos: SSO[]) => {
            this.ssos = ssos;
        });

        this.activatedRoute.data.subscribe((data: Data): void => {
            this.accountSettings = data.settings;
        });
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    toggleSSOLogin() {
        this.error = "";
        this.showZenLogin = !this.showZenLogin;
    }

    ssoLogin(sso: SSO) {
        const ssoLoginURL =
            sso.loginURL +
            (this.loginService.redirectUrl
                ? "?r=" + this.urlBuilderService.encodeRFC3986URIComponent(this.loginService.redirectUrl)
                : "");
        window.location.href = ssoLoginURL;
    }

    async onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        // if non sso access disable
        if (!this.accountSettings.allow_non_sso_login) {
            return;
        }

        this.loading = true;
        try {
            const result = await this.loginService.login(this.f.email.value, this.f.password.value);
            if (result.must_reset) {
                this.router.navigateByUrl("/set-password");
                return;
            }

            // Get the redirect URL from our auth service
            // If no redirect has been set, use the default
            if (this.loginService.redirectUrl) {
                const redirect = this.router.parseUrl(this.loginService.redirectUrl);

                if (redirect.queryParams.r) {
                    window.location.href = redirect.queryParams.r;
                } else {
                    window.location.href = this.loginService.redirectUrl;
                }
            } else {
                this.router.navigateByUrl("/dashboard");
            }
        } catch (error) {
            if (error.status === 401) this.error = "Invalid Username or Password";
            else if (error.error && error.error.message) this.error = error.error.message;
            else this.error = error.message;

            this.loading = false;
        }
    }
}
